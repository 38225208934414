(function () {

  angular.module('kmi.lms.achievements')
    .factory('achievementsService', achievementsService);

  /* @ngInject */
  function achievementsService($http) {
    return {
      getTypes: getTypes
    };

    function getTypes(options) {
      return $http.get('/a/achievements/types/', {params: options, cache: true})
        .then(function (response) {
          return response.data;
        });
    }
  }
})();
