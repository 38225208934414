(function () {

  angular.module('kmi.lms.admin.credits', [
    'kmi.lms.credits',
    'kmi.lms.core',
    'kmi.lms.search.common',
    'ui.bootstrap',
  ])
    .constant('editCreditTypeAdminRoles', (<any>window)._globalConfig.settings.editCreditTypeAdminRoles || [1, 100, 2330]);
})();
