(function () {

  angular.module('kmi.lms.advertising')
    .service('promotionService', promotionService);

  function promotionService($http) {

    return {
      getRecommendedItems: getRecommendedItems,
      getAccompanyingCourses: getAccompanyingCourses,
      getRecommendedCoursesByGroupCategory: getRecommendedCoursesByGroupCategory
    };

    function getRecommendedItems() {
      return $http.get('/a/advertising/recommendations/');
    }

    function getAccompanyingCourses(courseId) {
      return $http.get('/a/course_details/' + courseId + '/users_also_registered_for/');
    }

    function getRecommendedCoursesByGroupCategory(params) {
      return $http.get('/a/advertising/recommendations/courses/', {
        params: {
          group_category_id: params.groupCategory,
          count: params.count || 10,
          order: params.order || 3
        }
      });
    }
  }
})();
