(function () {

  angular.module('kmi.lms.admin.credits')
    .factory('creditTypesManagementService', creditTypesManagementService);

  /* @ngInject */
  function creditTypesManagementService($http, $q, _, $uibModal) {
    return {
      getCreditTypes: getCreditTypes,
      // isCreditTypeNameUnique: isCreditTypeNameUnique,
      getOrganizationCreditTypes: getOrganizationCreditTypes,
      handleCreditsWillChange: handleCreditsWillChange
    };

    function getCreditTypes(options) {
      return $http.get('/a/credit_types/', {params: options})
        .then(function (response) {
          return response.data;
        });
    }

    // function isCreditTypeNameUnique(creditType, name) {
    //   // Pass credit type id if it's provided or call sever without it.
    //   var url = ['/a/credit_types/', creditType.id ? creditType.id + '/' : '', 'unique/'].join('');
    //
    //   return $http.get(url, {
    //     params: {
    //       name: name
    //     }
    //   })
    //     .then(function (response) {
    //       return response.data.unique; // True/False
    //     }, function () {
    //       return false;
    //     });
    // }

    function getOrganizationCreditTypes(organizationId, params) {
      return $http.get(['/a/course/providers/organizations', organizationId, 'credit-types/'].join('/'), {params:params})
        .then(function (response) {
          return response.data;
        });
    }

    function handleCreditsWillChange(creditTypes) {
      var defer = $q.defer();

      if (angular.isArray(creditTypes) && _.filter(_.flatten(_.map(creditTypes, 'userAttributes'))).length > 0) {
        $uibModal.open({
          component: 'creditTypeRequireUserAttributes',
          backdrop: 'static',
          keyboard: false
        }).result
          .then(function () {
            defer.resolve();
          }, function () {
            defer.reject();
          });
      } else {
        defer.resolve();
      }
      return defer.promise;
    }
  }
})();
